import './regulation.scss'
import {useContact} from "../../graphql/query.hooks/contact.hook";
import MainBlock from "../../components/mainBlock/MainBlock";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import {useEffect} from "react";
import {useRule} from "../../graphql/query.hooks/rule.hooks";


function Regulation(){
    const translations = useTranslations()
    const rule = useRule()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return <div className={'regulation-wrapper'}>
        <MainBlock title={`${translations?.footer_regulation}`}/>
        <div className={'regulation'}
             dangerouslySetInnerHTML={{ __html: rule?.list?.text }}/>
    </div>

}export default Regulation