import './privacy-policy.scss'
import {useContact} from "../../graphql/query.hooks/contact.hook";
import MainBlock from "../../components/mainBlock/MainBlock";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import {useEffect} from "react";


function Privacy(){
    const translations = useTranslations()
    const contact = useContact()

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return <div className={'privacyPolice-wrapper'}>
        <MainBlock title={`${translations?.footer_privacy_police}`}/>
        <div className={'privacyPolice'}
             dangerouslySetInnerHTML={{ __html: contact?.list?.privacy_policy }}/>
    </div>

}export default Privacy