export const LANGUAGES = [
    {
        code:'hy',
        name:'HAY'
    },
    {
        code:'en',
        name:'ENG'
    },
]

export const NEWS_TYPES = {
    EVENTS : 'event',
    ARTICLE : 'article'
}