import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_FAQ = gql`
    query GetFaq{
        faq{   
          id
          answer
          question
        }
    }
`

export const useFaq = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_FAQ, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.faq, loading, fetchMore}
};
