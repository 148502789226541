import './newsCard.scss'
import {EventCheckboxIcon, ViewIcon} from "../../../../assets/images";
import moment from "moment";
import {Link} from "react-router-dom";
import {NEWS_TYPES} from "../../../../constants/constType";
import {GetUrlWithLocal} from "../../../../utils/getUrlWithLocal";

function NewsCard(props){
    const {data,slug,translations} = props

    return <Link to={GetUrlWithLocal(`/${slug}/detail/${data?.id}`)} className={'news-card-wrapper'}>
        <div className={'news-card-img-wrapper'}>
            <img src={data?.image_url} alt=""/>
        </div>
        <div className={'news-card-info-wrapper'}>
            {
                (slug === NEWS_TYPES?.EVENTS && data?.event_day_dates?.length) ?
                    <div className={'news-card-date'}>
                        {data?.event_day_dates?.map((date, index)=>{
                            return <span>
                                {index > 0 && <span className={'separator'}>|</span>}
                                {moment(date).format('ddd, MMM D, HH:mm')}
                            </span>
                        })}
                    </div> : ''
            }
            <div className={'news-card-title'}>
                {data?.title}
            </div>
            {
                data?.description ? <div className={'news-card-description'}
                                         dangerouslySetInnerHTML={{ __html: data?.description }}/>
                   : ''
            }
            {
                data?.is_for_club_members ? <div className={'news-card-members'}>
                   <EventCheckboxIcon/> {translations?.event_card_members}
                </div> : ''
            }
            <div className={'news-card-bottom-info'}>
                <div className={'news-card-created'}>
                    {translations?.event_posted_text} <span>{moment(data?.created_at).format('ll')}</span>
                </div>
                {(data?.public_metric?.read_count || data?.public_metric?.read_count === 0) &&
                <div className={'news-card-see'}>
                    <ViewIcon/> {data?.public_metric?.read_count}
                </div>}
            </div>
        </div>
    </Link>
}export default NewsCard
