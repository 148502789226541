import { gql, useMutation } from '@apollo/client';

const SEND_CONTACT = gql`
    mutation SendContact($args: SendContactArgs!){
        send_contact(args: $args){
            name
            surname
            email
            phoneNumber
            message
        }
    }
`

export const useSendContact = () => {
    const [sendContact, { data, loading, error, reset }] = useMutation(SEND_CONTACT, {
        context: {
            clientName: 'cms'
        }
    });
    return { sendContact, data, loading, error, reset  }
};
