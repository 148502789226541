import {useReactiveVar} from "@apollo/client";
import {appLanguage} from "../graphql/reactive-variables";

export function GetUrlWithLocal(path = "") {
    const language = useReactiveVar(appLanguage)
    let pathname = path;
    if(!path.startsWith('/')) {
        pathname = "/"+path
    }
    return path ? `${pathname}/${language}` : `/home/${language}`
}