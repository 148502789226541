import './staffCard.scss'

function StaffCard(props){
    const {item} = props

    return <div className={'staff-card-wrapper'}>
        <div className={'staff-card-img'}>
            <img src={item?.image_url} alt=""/>
        </div>
        <div className={'staff-card-info'}>
            <div className={'staff-card-name'}>
                {item?.name}
            </div>
            <div className={'staff-card-position'}>
                {item?.profession}
            </div>
        </div>
    </div>
}export default StaffCard