import './fitness.scss'
import MainBlock from "../../components/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import WorkoutCard from "../../components/uiElements/cards/workoutCard/WorkoutCard";
import AppBlock from "../../components/homepage/appBlock/AppBlock";
import { Tabs } from "antd";
import ClassesSlider from "../../components/classesSlider/ClassesSlider";
import { useEffect, useState } from "react";
import { useTranslations } from "../../graphql/query.hooks/translation.hook";
import { useFitness } from "../../graphql/query.hooks/fitnes.hook";
import { useWorkoutSection } from "../../graphql/query.hooks/workoutSection.hook";
import { useWorkout } from "../../graphql/query.hooks/workout.hook";

function Fitness () {
    const translations = useTranslations()
    const fitness = useFitness()
    const workoutSection = useWorkoutSection()
    const [workoutSectionId, setWorkoutSectionId] = useState(workoutSection?.list && workoutSection?.list?.[0]?.id)

    const workout = useWorkout(workoutSectionId)
    useEffect(() => {
        if (!workoutSectionId && workoutSection?.list?.length) {
            setWorkoutSectionId(workoutSection?.list?.[0]?.id)
        }
    }, [workoutSection])
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, [])

    const onChange = (id) => {
        setWorkoutSectionId(id)
    };

    const classes = workoutSection?.list?.map((item) => {
        return {
            key: item.id,
            label: `${item?.title}`,
        }
    })
    return <div className={'fitness-wrapper'}>
        <MainBlock title={`${translations?.fitness_page_title}`}/>
        <TickerBlock/>
        <div className={'workout-block'}>
            <div className={'workout-header'}>
                <div className={'workout-title'}>
                    {translations?.fitness_page_workout_title}
                </div>
                <div className={'workout-description'}>
                    {translations?.fitness_page_workout_description}
                </div>
            </div>
            <div className={'workout-card-block'}>
                {
                    fitness?.data?.services?.length && fitness?.data?.services.map(item => {
                        return <WorkoutCard item={item} key={item.id} translations={translations}/>
                    })
                }
            </div>
        </div>
        <div className={'classes-block'}>
            <div className={'classes-header'}>
                <div className={'classes-title'}>
                    {translations?.fitness_page_classes_title}
                </div>
                <div className={'classes-description'}>
                    {translations?.fitness_page_classes_description}
                </div>
            </div>
            <div className={'classes-wrapper'}>
                <Tabs value={workoutSectionId} items={classes} onChange={onChange}/>
                <ClassesSlider classes={workout?.list}/>
            </div>
        </div>
        <AppBlock/>
    </div>
}

export default Fitness
