import './visitModal.scss'
import { Modal } from "antd";
import { InputGroup } from "../../input-group/inputGroup";
import { useState } from "react";
import isEmail from "validator/es/lib/isEmail";
import { useTranslations } from "../../../../graphql/query.hooks/translation.hook";
import { LoadingOutlined } from "@ant-design/icons";
import { useSendVisit } from "../../../../graphql/mutation.hooks/visit.hook";
import {ModalCloseIcon} from "../../../../assets/images";

function VisitModal (props) {
    const { sendVisit, data, loading, error: reqError, } = useSendVisit();
    const { closeVisitModal, visible, membershipId } = props
    const translations = useTranslations()
    const [fieldsData, setFieldsData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
    })

    function validate () {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && !/^[+374]{4}[0-9]{8}$/.test(fieldsData[key]))) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function getInputValues (e) {
        let { name, value } = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        if (name === "phone" && value.length === 1 && value !== "+") {
            value = "+" + value
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    const sendMessage = () => {
        const payload = {
            service_id: membershipId,
            user_data: {
                first_name: fieldsData.firstName,
                last_name: fieldsData.lastName,
                email: fieldsData.email,
                phone: fieldsData.phone.slice(1)
            }
        }
        if (validate()) {
            sendVisit({
                variables: {
                    object: payload
                }
            }).catch((error) => {
            }).finally(() => {
                setFieldsData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                })
                closeVisitModal()
            })
        }
    }

    return <Modal
        visible={visible}
        wrapClassName={'visit-modal'}
        transitionName={""} // changed modal animation
        centered={true}
        closeIcon={<ModalCloseIcon/>}
        maskClosable={true}
        onCancel={closeVisitModal}>
        <div className={'visit-modal-wrapper'}>
            <div className={'visit-modal-header'}>
                <div className={'visit-modal-title'}>
                    {translations?.visit_modal_title}
                </div>
                <div className={'visit-modal-description'}>
                    {translations?.visit_modal_description}
                </div>
            </div>
            <div className={'visit-modals-inputs'}>
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.firstName}
                    error={error.firstName}
                    name={'firstName'}
                    placeholder={`${translations?.contacts_page_placeholder_name}`}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.lastName}
                    error={error.lastName}
                    name={'lastName'}
                    placeholder={`${translations?.contacts_page_placeholder_lastName}`}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.email}
                    error={error.email}
                    name={'email'}
                    placeholder={`${translations?.contacts_page_placeholder_email}`}
                    maxLength={100}
                    onChange={getInputValues}
                />
                <InputGroup
                    inputType={'input'}
                    type={"text"}
                    value={fieldsData.phone}
                    error={error.phone}
                    name={'phone'}
                    placeholder={`${translations?.contacts_page_placeholder_phoneNumber}`}
                    maxLength={12}
                    onChange={getInputValues}
                />
                <button className='send-visit'
                        onClick={sendMessage}>
                    {translations?.contacts_page_btn_send} {loading ?
                    <LoadingOutlined style={{ fontSize: 13, color: "#fff" }}/> : ''}
                </button>
            </div>
        </div>
    </Modal>
}

export default VisitModal
