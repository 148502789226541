import './contact.scss'
import { memo, useEffect, useState } from "react";
import MainBlock from "../../components/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import { FullscreenControl, Map, Placemark, YMaps } from "@pbe/react-yandex-maps";
import isEmail from "validator/es/lib/isEmail";
import { useTranslations } from "../../graphql/query.hooks/translation.hook";
import { InputGroup } from "../../components/uiElements/input-group/inputGroup";
import { useSendContact } from "../../graphql/mutation.hooks/contact.hook";
import { LoadingOutlined } from "@ant-design/icons";
import { useContact } from "../../graphql/query.hooks/contact.hook";
import { Marker } from "../../assets/images";
import { MapApiKey } from "../../configs/api-key";

function Contact () {
    const { sendContact, loading } = useSendContact();
    const translations = useTranslations()
    const contact = useContact()
    const [fieldsData, setFieldsData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    })
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        message: false,
    })

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    const mapData = {
        center: [40.182706, 44.493927],
        zoom: 15,
    }

    function validate () {
        const error = {};
        let result = true;
        Object.keys(fieldsData).forEach(key => {
            if (!fieldsData[key] || (key === 'email' && !isEmail(fieldsData?.email)) ||
                (key === 'phone' && !/^[+374]{4}[0-9]{8}$/.test(fieldsData[key]))) {
                error[key] = true;
                result = false;
            }

        })
        setError(error);
        return result;
    }

    function getInputValues (e) {
        let { name, value } = e.target
        if (name === "phone" && value && !/^[+\d]\d*$/.test(value)) {
            return
        }
        if (name === "phone" && value.length === 1 && value !== "+") {
            value = "+" + value
        }
        setFieldsData({
            ...fieldsData,
            [name]: value
        })
        setError({
            ...error,
            [name]: false
        })
    }

    const sendMessage = () => {
        const payload = {
            name: fieldsData.firstName,
            surname: fieldsData.lastName,
            email: fieldsData.email,
            phoneNumber: fieldsData.phone,
            message: fieldsData.message,
        }
        if (validate()) {
            sendContact({
                variables: {
                    args: payload
                }
            }).catch((error) => {
            }).finally(() => {
                setFieldsData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                })
            })
        }
    }

    return <div className={'contact-wrapper'}>
        <MainBlock title={`${translations?.contact_page_title}`}/>
        <TickerBlock/>
        <div className={'contact-block'}>
            <div className={'contact-us'}>
                <div className={'contact-us-title'}>
                    {translations?.contact_title}
                </div>
                <div className={'contact-us-description'}>
                    {translations?.contact_description}
                </div>
                <div className={'contact-input-wrapper'}>
                    <div className={'top-input-block'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.firstName}
                            error={error.firstName}
                            name={'firstName'}
                            placeholder={`${translations?.contacts_page_placeholder_name}`}
                            label={`${translations?.contacts_page_label_name}`}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.lastName}
                            error={error.lastName}
                            name={'lastName'}
                            placeholder={`${translations?.contacts_page_placeholder_lastName}`}
                            label={`${translations?.contacts_page_label_lastName}`}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                    </div>
                    <div className={'mid-input-block'}>
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.email}
                            error={error.email}
                            name={'email'}
                            placeholder={`${translations?.contacts_page_placeholder_email}`}
                            label={`${translations?.contacts_page_label_email}`}
                            maxLength={100}
                            onChange={getInputValues}
                        />
                        <InputGroup
                            inputType={'input'}
                            type={"text"}
                            value={fieldsData.phone}
                            error={error.phone}
                            name={'phone'}
                            placeholder={`${translations?.contacts_page_placeholder_phoneNumber}`}
                            label={`${translations?.contacts_page_label_phoneNumber}`}
                            maxLength={12}
                            onChange={getInputValues}
                        />
                    </div>
                    <InputGroup
                        inputType={'textarea'}
                        type={"text"}
                        value={fieldsData.message}
                        error={error.message}
                        name={'message'}
                        placeholder={`${translations?.contacts_page_placeholder_message}`}
                        label={`${translations?.contacts_page_label_message}`}
                        onChange={getInputValues}
                    />
                    <div className={'btn-block'}>
                        <button className='sent-email'
                                onClick={() => sendMessage()}>
                            {translations?.contacts_page_btn_send} {loading ?
                            <LoadingOutlined style={{ fontSize: 13, color: "#fff" }}/> : ''}
                        </button>
                        <a href={contact?.list?.url} className='url-btn' target={'_blank'}>
                            {translations?.contacts_url_btn}
                        </a>
                    </div>
                </div>
            </div>
            <div className={'address-info-block'}>
                <MemoizedMap data={mapData}/>
                <div className={'contact-info-block'}>
                    <div className={'contact-info-title'}>
                        {translations?.contact_info_title}
                    </div>
                    <div className={'address-block'}>
                        <div className={'address-title'}>
                            {translations?.contacts_address_title}
                        </div>
                        <a className={'address'}
                           target={"_blank"}
                           href={'https://yandex.ru/maps/org/oranzh_fitnes/1698075629/?ll=44.488028%2C40.183321&z=16.8'}>
                            {contact?.list?.address}
                        </a>
                    </div>
                    <div className={'working-hours-block'}>
                        <div className={'working-hours-title'}>
                            {translations?.contact_working_hour_title}
                        </div>
                        <div className={'working-hours'}
                             dangerouslySetInnerHTML={{ __html: contact?.list?.working_times }}/>
                    </div>
                    <div className={'phone-number-block'}>
                        <div className={'phone-number-title'}>
                            {translations?.contact_number_title}
                        </div>
                        <div className={'phone-number'}
                             dangerouslySetInnerHTML={{ __html: contact?.list?.phones }}/>
                    </div>
                </div>
            </div>
        </div>
    </div>

}

export default Contact

function MapSection ({ data }) {
    return <div className={'map-block'}>
        <YMaps query={{
            apikey: MapApiKey,
            lang: 'en'
        }}>
            <Map defaultState={data} cursors={"inherit"} className={'yandexMap'}>
                <Placemark
                    geometry={[40.183334, 44.488004]}
                    classname={'customIcon'}
                    options={{
                        iconLayout: 'default#image',
                        iconImageHref: Marker,
                        iconImageSize: [40, 55],
                        iconImageOffset: [-15, -60],
                    }}
                />
                <FullscreenControl options={{
                    position: {
                        bottom: 28,
                        right: 26,
                    },
                }}/>
            </Map>
        </YMaps>
    </div>
}

const MemoizedMap = memo(MapSection, (_, props) => {
    return props?.data
})
