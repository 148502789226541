import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'moment/locale/hy-am';
import 'swiper/css';
import './index.scss';

import { ApolloProvider } from '@apollo/client';
import { apolloClient } from "./graphql/client";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={apolloClient}>
        <App />
    </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
