import { makeVar } from "@apollo/client";
import { Languages } from "../utils/constanats";

// console.log('window.location', window.location.pathname)
const pathname = window?.location?.pathname;
const pathLanguage = pathname?.split('/').at(-1);
let language;
if (pathLanguage === Languages.en || pathLanguage === Languages.hy) {
    language = pathLanguage
} else {
    language = localStorage.getItem('language') || Languages.hy;
}

localStorage.setItem('language', language)
export const appLanguage = makeVar(language);
