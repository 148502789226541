import MainBlock from "../../components/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import AppBlock from "../../components/homepage/appBlock/AppBlock";
import {useState,useEffect} from "react";
import './cafe.scss'
import ImagesSlider from "../../components/uiElements/modals/imagesSlider/ImagesSlider";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import {useCafe} from "../../graphql/query.hooks/cafe.hook";


function Cafe(){
    const [sliderOpen,setSliderOpen] = useState(false)
    const [imagePathList,setImagePathList] = useState([])
    const [currentImageIndex,setCurrentImageIndex] = useState(0)
    const translations = useTranslations()
    const cafe = useCafe()
    const showCount = window.innerWidth < 1000 ? 4 : 5

    function openImagesSlider(modalImagePathList, imageIndex) {
        setSliderOpen(true)
        setCurrentImageIndex(imageIndex)
        setImagePathList(modalImagePathList)
    }

    function closeImagesSlider() {
        setSliderOpen(false)
        setCurrentImageIndex(0)
        setImagePathList([])
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    function pdfFile() {
        if (cafe?.data?.pdf_url) {
            window.open(cafe?.data?.pdf_url, '_blank');
        }
    }

    return <div className={'cafe-wrapper'}>
        <MainBlock title={`${translations?.cafe_page_title}`}/>
        <TickerBlock/>
        <div className={'cafe-manu-block'}>
            <div className={'menu-header'}>
                <div className={'menu-title'}>
                    {translations?.cafe_page_menu_title}
                </div>
                <div className={'menu-block'}>
                    <div className={'menu-description'}>
                        {translations?.cafe_page_menu_description}
                    </div>
                    <div className={'menu-pdf'} onClick={pdfFile}>
                        {translations?.cafe_page_menu_pdf_title}
                    </div>
                </div>
            </div>
            <div className={'cafe-menu-images'}>
                {
                    !!cafe?.data?.images?.length && cafe?.data?.images.slice(0, showCount).map((img, index) => {
                        return <div className="menu-images-item"
                                    key={index}
                                    onClick={() => cafe?.data?.images?.[0] && openImagesSlider(cafe, index)}>
                            <div className="menu-images-content">
                                <img src={img?.image_url} alt=""/>
                                {index === showCount - 1 && (cafe?.data?.images?.length > showCount) &&
                                    <div className={'more-content'}>
                                        <div className={"text-count"}>+{cafe?.data?.images?.length + 1 - 5} more</div>
                                    </div>}
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
        <AppBlock/>
        <ImagesSlider visible={sliderOpen}
                      modalImagePathList={imagePathList?.data?.images}
                      modalCurrentImageIndex={currentImageIndex}
                      closeModal={closeImagesSlider}/>
    </div>

}export default Cafe
