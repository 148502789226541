import './about.scss'
import MainBlock from "../../components/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import {PlayBtnIcon} from "../../assets/images";
import AppBlock from "../../components/homepage/appBlock/AppBlock";
import {useState,useEffect,useRef} from "react";
import {Link} from "react-router-dom";
import {useAbout} from "../../graphql/query.hooks/about.hook";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import {GetUrlWithLocal} from "../../utils/getUrlWithLocal";

function About() {
    const about = useAbout()
    const translations = useTranslations()
    const [videoPlay,setVideoPlay] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    useEffect(() => {
        window.addEventListener('scroll',videoPause)
        return () => window.removeEventListener('scroll',videoPause)
    },[])

    function videoState(){
        setVideoPlay(false)
        let video = document.querySelector('video')
        video.load();
    }
    function videoPause() {
        const scrollY = window.scrollY || window.pageYOffset;
        const videoBottom = ref.current.getBoundingClientRect().bottom + scrollY;
        const videoTop = ref.current.getBoundingClientRect().top + scrollY;
        // console.log(videoBottom,videoTop,'window.scrollY')
        if(scrollY + (videoBottom - videoTop) < videoTop || window.scrollY > videoBottom){
            let video = document.querySelector('video')
            setVideoPlay(false)
            video.pause()
        }
    }

    function videoStart() {
        let video = document.querySelector('video')
        setVideoPlay(true)
        video.play()
    }

    return <div className={'about-wrapper'}>
        <MainBlock title={`${translations?.about_page_title}`}/>
        <TickerBlock/>
        <div className={'about-info-block'}>
            <div className={'info-text-block'}>
                <div className={'about-info-title'}>
                    {about?.data?.title}
                </div>
                <div className={'about-info-description'}>
                    {about?.data?.description}
                </div>
                <Link to={GetUrlWithLocal('/contact')} className={'about-info-btn'}>
                    {translations?.about_btn_title}
                </Link>
            </div>
            <div className={'info-img-block'}>
                <div className={'img-wrapper'}>
                    <img src={about?.data?.logo_url} alt=""/>
                </div>
            </div>
        </div>
        {
            !!about?.data?.blocks?.length && <div className={'about-advantages-block'}>
                <div className={'about-advantages-title'}>
                    {about?.data?.block_title}
                </div>
                <div className={'about-advantages'}>
                    {
                        !!about?.data?.blocks?.length && about?.data?.blocks?.map(item => {
                            return <div className={'advantages-item'} key={item?.id}>
                                <div className={'advantages-item-title'}>
                                    {item?.title}
                                </div>
                                <div className={'advantages-item-descriptions'}>
                                    {item?.description}
                                </div>
                                <div className={'advantages-item-icon'}>
                                    <img src={item?.file_url} alt=""/>
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
        <AppBlock/>
        <div className={'about-video-block'}>
            <div className={'about-video-title'}>
                {about?.data?.video_title}
            </div>
            <div className={'about-video-description'}>
                {about?.data?.video_description}
            </div>
            <div className={'video-block'}>
                {videoPlay ? '' :  <div onClick={videoStart}>
                    <PlayBtnIcon/>
                </div>}
                <video playsInline
                       muted
                       ref={ref}
                       controls={videoPlay}
                       onEnded={videoState}
                       src={about?.data?.video_url}
                       poster={about?.data?.thumbnail_url}
                       className={'video'}
                >
                </video>
            </div>
        </div>
    </div>
}

export default About
