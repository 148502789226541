import {Animation, LinkArrIcon} from "../../../assets/images";
import './mainBlock.scss'
import {Link} from "react-router-dom";
import {GetUrlWithLocal} from "../../../utils/getUrlWithLocal";

function MainBlock (props) {
    const { resource,translations} = props

    return <div className={'mainBlock-wrapper'}>
        <div className={'main-img-block'}>
            <img src={resource?.home_image_url} alt=""/>
        </div>
        <div className={'main-img-responsive-block'}>
            <img src={resource?.home_image_responsive_url} alt=""/>
        </div>
        {
            resource?.home_text_is_hidden && <div className={'main-text-block'}>
                <div className={'main-block-title'}
                     dangerouslySetInnerHTML={{__html: translations?.homepage_main_block_title}}/>
                <Link to={GetUrlWithLocal('/contact')} className={'main-block-link'}>
                    {translations?.homepage_main_block_apply} <LinkArrIcon/>
                </Link>
            </div>
        }
        <div className={'main-animation-block'}>
            <img src={Animation} alt=""/>
        </div>
    </div>
}

export default MainBlock
