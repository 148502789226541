import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_EVENT_ONE = gql`
    query GetEventOne($event_id_or_slug_eq:String!){
        event_one(where:{event_id_or_slug_eq:$event_id_or_slug_eq}) {
            id
            title
            description
            image_url
            cover_url
            is_for_club_members
            event_day_dates
            created_at
            updated_at
            web_cover_url
        }
    }
`

export const useEventOne = (eventId) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_EVENT_ONE, {
        variables: { event_id_or_slug_eq: eventId },
        skip: !eventId,
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.event_one, loading, fetchMore }
};
