import './spa.scss'
import MainBlock from "../../components/mainBlock/MainBlock";
import TickerBlock from "../../components/homepage/tickerBlock/TickerBlock";
import {Collapse} from "antd";
import {useState,useEffect} from "react";
import AppBlock from "../../components/homepage/appBlock/AppBlock";
import StaffCard from "../../components/uiElements/cards/staffCard/StaffCard";
import TrainerModal from "../../components/uiElements/modals/trainerModal/TrainerModal";
import {useTranslations} from "../../graphql/query.hooks/translation.hook";
import {useSpa} from "../../graphql/query.hooks/spa.hook";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

const {Panel} = Collapse;

function Spa() {
    const spa = useSpa()
    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    },[])
    const translations = useTranslations()

    const [spaImg, setSpaImg] = useState(spa?.data?.services && spa?.data?.services[0]?.image_url)
    const [trainerModalOpen,setTrainerModalOpen] = useState(false)
    const [trainerData,setTrainerData] = useState({})

    function openTrainerModal(trainer) {
        setTrainerModalOpen(true)
        setTrainerData(trainer)
    }

    function closeTrainerModal() {
        setTrainerModalOpen(false)
    }

    function pdfFile() {
        if (spa?.data?.link) {
            window.open(spa?.data?.link, '_blank');
        }
    }
    return <div className={'spa-wrapper'}>
        <MainBlock title={`${translations?.spa_page_title}`}/>
        <TickerBlock/>
        <div className={'spa-service-block'}>
            <div className={'spa-service-block-header'}>
                <div className={'spa-service-block-title'}>
                    {translations?.spa_page_service_title}
                </div>
                <div className={'spa-service-block-info'}>
                    <div className={'spa-service-block-description'}>
                        {translations?.spa_page_service_description}
                    </div>
                    <div className={'spa-service-block-work'}>
                        {translations?.spa_page_service_work_text} <span>{translations?.spa_page_service_work_hours}</span>
                    </div>
                    <div className={'spa-service-block-price'} onClick={pdfFile}>
                        {translations?.spa_page_service_priceList_text}
                    </div>
                </div>
            </div>
            <div className={'spa-service-block-wrapper'}>
                <Collapse
                    expandIconPosition={'end'}
                    defaultActiveKey={['1']}
                    accordion
                    className={'spa-information-wrapper'}
                    expandIcon={({isActive}) => isActive ? '' : ''}
                >
                    {spa?.data?.services?.length && spa?.data?.services?.map(item => {
                        return <Panel header={item?.title}
                                      key={item?.id}
                                      onClick={() => setSpaImg(item?.image_url)}
                                      className={'spa-information-blocks'}>
                            <div className={'spa-services'}>
                                <div className={'spa-information'}>
                                    <div className={'spa-information-blocks-description'}>
                                        {item?.description}
                                    </div>
                                    <div className={'spa-information-blocks-reservation'}>
                                        {translations?.spa_page_service_reservation_text} <a href={`tel:${item?.phone}`}>
                                        {item?.phone}</a>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    })
                    }
                </Collapse>
                <div className={'spa-service-img'}>
                    <div className={'spa-img-block'}>
                        <img src={spaImg} alt=""/>
                    </div>
                </div>
            </div>
        </div>
        <AppBlock/>
        <div className={'spa-staff-block'}>
            <div className={'spa-staff-header'}>
                <div className={'spa-staff-title'}>
                    {translations?.spa_page_staff_title}
                </div>
                <div className={'spa-staff-description'}>
                    {translations?.spa_page_staff_description}
                </div>
            </div>
            <div className={'spa-staff-wrapper'}>
                {
                    spa?.data?.workers?.length   && spa?.data?.workers.map((item,index) => {
                        return <div className={'spa-staff-card-wrapper'}
                                    key={index}
                                    onClick={() => openTrainerModal(item)}>
                            <StaffCard item={item}/>
                        </div>
                    })
                }
            </div>
            <div className={'responsive-staff-block'}>
                <Swiper
                    spaceBetween={'25'}
                    slidesPerView={1.2}
                    loop={true}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                >
                    { spa?.data?.workers?.length &&  spa?.data?.workers?.map(item => {
                        return <SwiperSlide key={item.id}>
                            <div className={'workout-staff-card-wrapper'}
                                 onClick={() => openTrainerModal(item)}>
                                <StaffCard item={item}/>
                            </div>
                        </SwiperSlide>
                    })}
                </Swiper>
            </div>
        </div>
        <TrainerModal visible={trainerModalOpen}
                      trainerData={trainerData}
                      closeModal={closeTrainerModal}/>
    </div>
}

export default Spa
