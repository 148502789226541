import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_WORKOUT_ONE = gql`
    query GetWorkoutOne($workout_id_eq:String!){
        workout_one(where:{workout_id_eq:$workout_id_eq}) {
            id
            title
            description
            image_url
        }
    }
`

export const useWorkoutOne = (workoutId) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_WORKOUT_ONE, {
        variables: { workout_id_eq: workoutId },
        skip: !workoutId,
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.workout_one, loading, fetchMore }
};
