import './faqBlock.scss'
import { Collapse } from 'antd';
import {FaqMinusIcon, FaqPlusIcon} from "../../../assets/images";
import {useFaq} from "../../../graphql/query.hooks/faq.hook";
import {Link} from "react-router-dom";
const { Panel } = Collapse;


function FaqBlock(props){
    const {translations} = props
    const faq = useFaq()

    return <div className={'faq-wrapper'}>
        <div className={'faq-left-block'}>
            <div className={'faq-block-title'}>
                {translations?.homepage_faq_title}
            </div>
            <div className={'faq-block-description'}>
                {translations?.homepage_faq_description}
            </div>
            <Link to={'/contact'} className={'faq-contact-btn'} >
                {translations?.homepage_faq_contact_btn}
            </Link>
        </div>
        <div className={'faq-right-block'}>
            <Collapse
                expandIconPosition={'end'}
                className={'faq-information-wrapper'}
                expandIcon={({ isActive }) => isActive ? <FaqMinusIcon/> : <FaqPlusIcon/>}
            >
                {faq?.list?.length && faq?.list?.map(item => {
                    return <Panel header={item?.question}
                                  key={item?.id}
                                  className={'faq-information-blocks'}>
                        <div className={'faq-information-blocks-description'}>
                            {item?.answer}
                        </div>
                    </Panel>
                })
                }
            </Collapse>
            <button className={'faq-contact-responsive-btn'}>
                {translations?.homepage_faq_contact_btn}
            </button>
        </div>
    </div>

}export default FaqBlock