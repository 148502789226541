import { useQuery, gql } from '@apollo/client';

const GET_RESOURCE = gql`
    query GetResource{
        resource {
            home_video_url
            home_image_url
            thumbnail_url
            home_text_is_hidden
            home_image_responsive_url
        }
    }
`

export const useResource = () => {
    const { data } = useQuery(GET_RESOURCE);
    return data?.resource
};
