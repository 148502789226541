import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_POOL = gql`
    query GetPool{
        pool{
            id
            title
            description
            image_url
            logo_file_url
            updated_at
            images{
                id
                image_url
            }
        }
    }
`

export const usePool = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading} = useQuery(GET_POOL, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.pool, loading }
};
