import './articleDetail.scss'
import { useArticles } from "../../../graphql/query.hooks/article.hook";
import moment from "moment";
import { ViewIcon } from "../../../assets/images";
import NewsCard from "../../../components/uiElements/cards/newsCard/NewsCard";
import { useEffect } from "react";
import { useTranslations } from "../../../graphql/query.hooks/translation.hook";
import { useParams } from "react-router-dom";
import { useArticleOne } from "../../../graphql/query.hooks/articleOne.hook";
import {NEWS_TYPES} from "../../../constants/constType";

function ArticleDetail () {
    const params = useParams()
    const { data: articlesData, loading: loadingArticles } =
        useArticles({
            offset: 0,
            limit: 4,
            categoryId: null
        });
    const articleOne = useArticleOne(params?.id);
    const translations = useTranslations()
    const item = articleOne?.data

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [params?.id])

    return <div className={'article-detail-wrapper'}>
        <div className={'article-detail-header'}>
            <div className={'article-detail-img-block'}>
                {/*<img src={item?.image_url} alt=""/>*/}
                {
                    window.innerWidth < 1000 ? <img src={item?.cover_url} alt=""/>
                        : item?.web_cover_url  ? <img src={item?.web_cover_url} alt=""/> : <img src={item?.cover_url} alt=""/>
                }
            </div>
        </div>
        <div className={'article-detail-info-block'}>
            <div className={'article-detail-title'}>
                {item?.title}
            </div>
            <div className={'article-detail-description'}
                 dangerouslySetInnerHTML={{ __html: item?.description }}/>
            <div className={'article-detail-info-bottom'}>
                <div className={'article-detail-date'}>
                    {translations?.event_posted_text} <span>{moment(item?.created_at).format('ll')}</span>
                </div>
                <div className={'article-detail-view'}>
                    <ViewIcon/> {item?.public_metric?.read_count}
                </div>
            </div>
        </div>
        <div className={'article-detail-article-wrapper'}>
            <div className={'article-detail-article-header'}>
                <div className={'article-detail-article-title'}>
                    {translations?.event_detail_article_title}
                </div>
                <div className={'article-detail-article-description'}>
                    {translations?.event_detail_article_description}
                </div>
            </div>
            <div className={'article-detail-article-block'}>
                {
                    articlesData?.records?.length && articlesData?.records?.filter(a => a.id !== params.id).slice(0,3)?.map((item, index) => {
                        return <div className={'article-detail-article'} key={index}>
                            <NewsCard data={item} slug={NEWS_TYPES?.ARTICLE} translations={translations}/>
                        </div>
                    })
                }
            </div>
        </div>
    </div>
}

export default ArticleDetail
