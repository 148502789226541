import './mainBlock.scss'

function MainBlock(props) {
    const {title} = props
    return <div className={'main-block-wrapper'}>
        <div className={'mainBg-text'}>
            Orange
        </div>
        <div className={'main-block-title'}>
            {title}
        </div>
    </div>

}

export default MainBlock