import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_SPA = gql`
    query GetSpa{
        spa{
            id
            link
            logo_url
            updated_at
            workers{
                id
                name
                profession
                image_url
            }
            services{
                id
                phone
                title
                description
                image_url
            }
        }
    }
`

export const useSpa = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading} = useQuery(GET_SPA, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.spa, loading }
};
