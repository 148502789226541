import './eventDetail.scss'
import moment from "moment";
import NewsCard from "../../../components/uiElements/cards/newsCard/NewsCard";
import { useEffect } from "react";
import { useTranslations } from "../../../graphql/query.hooks/translation.hook";
import { useEventOne } from "../../../graphql/query.hooks/eventOne.hook";
import { useParams } from "react-router-dom";
import { useEvents } from "../../../graphql/query.hooks/event.hook";
import { NEWS_TYPES } from "../../../constants/constType";
import { EventCheckboxIcon } from "../../../assets/images";

function EventDetail () {
    const params = useParams()
    const { data: eventsData, loading: loadingEvents } =
        useEvents({ offset: 0, limit: 4 });
    const eventOne = useEventOne(params?.id);
    const translations = useTranslations()
    const item = eventOne?.data

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [params?.id])

    // console.log(eventsData,'eventsData')

    return <div className={'event-detail-wrapper'}>
        <div className={'event-detail-header'}>
            <div className={'event-detail-img-block'}>
                {
                    window.innerWidth < 1000 ? <img src={item?.cover_url} alt=""/>
                        : <img src={item?.web_cover_url} alt=""/>
                }
            </div>
            {
                item?.is_for_club_members ? <div className={'event-detail-members'}>
                    <EventCheckboxIcon/> {translations?.event_card_members}
                </div> : ''
            }
        </div>
        <div className={'event-detail-info-wrapper'}>
            <div className={'event-detail-info-block'}>
                <div className={'event-detail-date'}>
                    {item?.event_day_dates?.length && <div className={'news-card-date'}>
                        {item?.event_day_dates?.map((date, index) => {
                            return <span>
                                {index > 0 && <span className={'separator'}>|</span>}
                                {moment(date).format('ddd, MMM D, HH:mm')}
                            </span>
                        })}
                    </div>}
                </div>
                <div className={'event-detail-title'}>
                    {item?.title}
                </div>
                <div className={'event-detail-description'}
                     dangerouslySetInnerHTML={{ __html: item?.description }}/>
                <div className={'event-detail-info-bottom'}>
                    <div className={'event-detail-date'}>
                        {translations?.event_posted_text} <span>{moment(item?.created_at).format('ll')}</span>
                    </div>
                </div>
            </div>
            <div className={'download-wrapper'}>
                <div className={'download_block'}>
                    <span>
                        {translations?.event_detail_link_description1}
                    </span>
                    <p>
                        {translations?.event_detail_link_description2}
                    </p>
                    <a href="https://orangefit.am/"  target={'_blank'} className={'download_btn'}>
                        {translations?.event_detail_link_btn}
                    </a>
                </div>
            </div>
        </div>
        {
            eventsData?.records?.length > 1 ? <div className={'event-detail-article-wrapper'}>
                <div className={'event-detail-article-header'}>
                    <div className={'event-detail-article-title'}>
                        {translations?.event_detail_event_title}
                    </div>
                    <div className={'event-detail-article-description'}>
                        {translations?.event_detail_event_description}
                    </div>
                </div>
                <div className={'event-detail-article-block'}>
                    {
                        eventsData?.records?.length && eventsData?.records?.filter(e => e.id !== params.id).slice(0,3)?.map(item => {
                            return <div className={'event-detail-article'}>
                                <NewsCard data={item} slug={NEWS_TYPES?.EVENTS} translations={translations}/>
                            </div>
                        })
                    }
                </div>
            </div> : ''
        }
    </div>
}

export default EventDetail
