import { gql, useMutation } from '@apollo/client';

const SEND_VISIT = gql`
    mutation SendVisit($object: ServiceRequestInsertOneInput!){
        service_request_insert_one(object: $object)
    }
`

export const useSendVisit = () => {
    const [sendVisit, { data, loading, error }] = useMutation(SEND_VISIT, {
        context: {
            clientName: 'cms'
        }
    });
    return { sendVisit, data, loading, error }
};
