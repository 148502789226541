import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_TEAM = gql`
    query GetTeam{
        team{
            id
            logo_url
            trainers{
                id
                name
                profession
                bio
                image_url
            }
        }
    }
`

export const useTeam = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading } = useQuery(GET_TEAM, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.team, loading }
};
