import './appBlock.scss'
import {AndroidDownloadIcon, ImgApp, IosDownloadIcon} from "../../../assets/images";
import {useTranslations} from "../../../graphql/query.hooks/translation.hook";

function AppBlock(props){
    const translations = useTranslations()

    return <div className={'app-block-wrapper'}>
        <div className={'app-left-block'}>
            <div className={'app-block-title'}>
                {translations?.homepage_app_title}
            </div>
            <div className={'app-block-description'}>
                {translations?.homepage_app_description}
            </div>
            <div className={'app-download-block'}>
                <a href={'https://play.google.com/store/apps/details?id=am.solidy.orange&pli=1'} target={'_blank'}>
                    <AndroidDownloadIcon/>
                </a>
                <a href={'https://apps.apple.com/am/app/orange-fitness/id1665667049'} target={'_blank'}>
                    <IosDownloadIcon/>
                </a>
            </div>
        </div>
        <div className={'app-right-block'}>
            <div className={'app-img-wrapper'}>
                <img src={ImgApp} alt=""/>
            </div>
        </div>
    </div>
}export default AppBlock