import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_WORKOUT_SECTION = gql`
    query GetWorkoutSection{
        workout_section{   
          id
         title
        }
    }
`

export const useWorkoutSection = () => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_WORKOUT_SECTION, {
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { list: data?.workout_section, loading, fetchMore}
};
