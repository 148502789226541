import React from 'react';
import { BrowserRouter } from "react-router-dom";
import RoutesBlocks from "../src/routing";

function App () {
    return (
        <BrowserRouter>
            <RoutesBlocks/>
        </BrowserRouter>
    );
}

export default App;
