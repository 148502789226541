import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";

const GET_ARTICLES = gql`
    query GetArticles ($offset: Int!, $limit: Int!, $categoryId: [String!] ){
        article(offset: $offset, limit: $limit, where: {category_id_in: $categoryId}) {
            current_offset
            total_count
            records {
                id
                title
                slug
                description
                image_url
                created_at
                public_metric{
                    read_count
                }
                categories{
                    id
                    title
                }
            }
        }
    }
`

export const useArticles = ({ offset = 0, limit = 5, categoryId=null  }={}) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading, fetchMore } = useQuery(GET_ARTICLES, {
        variables: { offset, limit, categoryId: categoryId ? [categoryId]: null },
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.article, loading, fetchMore }
};
