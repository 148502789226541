import './section.scss'
import TickerBlock from "../../../components/homepage/tickerBlock/TickerBlock";
import StaffCard from "../../../components/uiElements/cards/staffCard/StaffCard";
import {useState, useEffect} from "react";
import ImagesSlider from "../../../components/uiElements/modals/imagesSlider/ImagesSlider";
import TrainerModal from "../../../components/uiElements/modals/trainerModal/TrainerModal";
import {useTranslations} from "../../../graphql/query.hooks/translation.hook";
import {useFitnessServiceOne} from "../../../graphql/query.hooks/fitnessServiceOne.hook";
import {useParams} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay} from "swiper/modules";

function FitnessSection(props) {
    const translations = useTranslations()
    const params = useParams()
    const fitnessServiceOne = useFitnessServiceOne(params?.id)
    const [sliderOpen,setSliderOpen] = useState(false)
    const [trainerModalOpen,setTrainerModalOpen] = useState(false)
    const [imagePathList,setImagePathList] = useState([])
    const [currentImageIndex,setCurrentImageIndex] = useState(0)
    const [trainerData,setTrainerData] = useState({})
    const showCount = window.innerWidth < 1000 ? 4 : 5

    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    },[])

   function openImagesSlider(modalImagePathList, imageIndex) {
        setSliderOpen(true)
       setCurrentImageIndex(imageIndex)
       setImagePathList(modalImagePathList)
    }
    function openTrainerModal(trainer) {
        setTrainerModalOpen(true)
        setTrainerData(trainer)
    }

    function closeImagesSlider() {
        setSliderOpen(false)
        setCurrentImageIndex(0)
        setImagePathList([])
    }
    function closeTrainerModal() {
        setTrainerModalOpen(false)
    }


    return <div className={'workout-wrapper'}>
        <div className={'workout-top-block'}>
            <div className={'workout-page-header'}>
                <div className={'workout-page-img'}>
                    <img src={fitnessServiceOne?.data?.image_url} alt=""/>
                </div>
                <div className={'workout-page-info'}>
                    <div className={'workout-page-title'}>
                        {fitnessServiceOne?.data?.title}
                    </div>
                    <div className={'workout-page-description'}>
                        {fitnessServiceOne?.data?.description}
                    </div>
                </div>
                <span className={'workout-gradient-block'}/>
            </div>
            <TickerBlock/>
        </div>
        {
            !!fitnessServiceOne?.data?.trainers?.length  && <div className={'workout-staff-block'}>
                <div className={'workout-staff-header'}>
                    <div className={'workout-staff-title'}>
                        {translations?.workout_page_staff_title}
                    </div>
                    <div className={'workout-staff-description'}>
                        {translations?.workout_page_staff_description}
                    </div>
                </div>
                <div className={'workout-staff-wrapper'}>
                    {
                        !!fitnessServiceOne?.data?.trainers?.length  && fitnessServiceOne?.data?.trainers.map(item => {
                            return <div className={'workout-staff-card-wrapper'}
                                        key={item.id}
                                        onClick={() => openTrainerModal(item)}>
                                <StaffCard item={item}/>
                            </div>
                        })
                    }
                </div>
                <div className={'responsive-staff-block'}>
                    <Swiper
                        spaceBetween={'25'}
                        slidesPerView={1.2}
                        loop={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay]}
                    >
                        { fitnessServiceOne?.data?.trainers?.length &&  fitnessServiceOne?.data?.trainers?.map(item => {
                            return <SwiperSlide key={item.id}>
                                <div className={'workout-staff-card-wrapper'}
                                     onClick={() => openTrainerModal(item)}>
                                    <StaffCard item={item}/>
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        }
        {
            !!fitnessServiceOne?.data?.images?.length && <div className={'workout-gallery-block'}>
                <div className={'workout-gallery-title'}>
                    {translations?.workout_page_gallery_title}
                </div>
                <div className={'gallery-wrapper'}>
                    {
                        !!fitnessServiceOne?.data?.images?.length && fitnessServiceOne?.data?.images?.slice(0, showCount).map((img, index) => {
                            return <div className="media-item"
                                        key={index}
                                        onClick={() => fitnessServiceOne?.data?.images?.[0] && openImagesSlider(fitnessServiceOne?.data?.images, index)}>
                                <div className="media-item-content">
                                    <img src={img?.image_url} alt=""/>
                                    {index === showCount - 1 && (fitnessServiceOne?.data?.images?.length > showCount) &&
                                        <div className={'more-content'}>
                                            <div className={"text-count"}>+{fitnessServiceOne?.data?.images?.length - 5} more</div>
                                        </div>}
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
        {
            !!fitnessServiceOne?.data?.images?.length && <ImagesSlider visible={sliderOpen}
                                                                       modalImagePathList={imagePathList}
                                                                       modalCurrentImageIndex={currentImageIndex}
                                                                       closeModal={closeImagesSlider}/>
        }
        <TrainerModal visible={trainerModalOpen}
                      trainerData={trainerData}
                      closeModal={closeTrainerModal}/>
    </div>
}

export default FitnessSection
