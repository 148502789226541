import { useQuery, gql, useReactiveVar } from '@apollo/client';
import { appLanguage } from "../reactive-variables";
import { PackageTypes } from "../../utils/constanats";

const GET_PACKAGES = gql`
    query GetPackages($type: [ServiceType!]! ){
        service(where:{type_in: $type}){
            id
            name
            name_color
            title
            information
            description
            details
            is_popular
            duration_options
            type
            web_information
            web_description
            web_details
        }
    }
`

export const usePackages = (type = PackageTypes.Package) => {
    const language = useReactiveVar(appLanguage)
    const { data, loading } = useQuery(GET_PACKAGES, {
        variables: {
            type: [type]
        },
        context: {
            headers: {
                "accept-language": language
            }
        }
    });
    return { data: data?.service, loading }
};
